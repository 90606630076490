<template>
  <div
    class="DAV-workshop__trunk"
    :class="{'DAV-workshop__trunk--option-mode': $route.path === '/option'}"
  >
    <Spinner v-if="isLoading" :propsPartial="true" />
    <DualballSpinner v-if="isFetching" />
    <component-title color="green" @goPrev="goPrev">
      <h3 slot="title">
        <ul class="title-wrap">
          <li @click="changeMenu(1)" :class="{'title-active': menu === 1}">
            내 이미지
          </li>
          <li class="line"></li>
          <li
            @click="changeMenu(2)"
            :class="{'title-active': menu === 2}"
            class="ga_e_15"
          >
            무료 이미지
          </li>
        </ul>
      </h3>
    </component-title>
    <FileUsage v-if="menu === 1" color="green" :fileUsage="fileUsage" />
    <div v-if="menu === 2" class="search-wrap">
      <div class="search-input">
        <input
          placeholder="찾고 싶은 이미지를 검색해보세요!"
          v-model="searchInput"
          @keyup.enter="unsplashImageList('search')"
        />
        <div
          v-if="searchInput !== ''"
          class="cancel-button"
          @click="unsplashSearchDelete()"
        >
          ×
        </div>
      </div>
      <div class="search-button ga_e_16" @click="unsplashImageList('search')">
        검색
      </div>
    </div>
    <!-- menu1 내 이미지 -->
    <div class="DAV-blockClass DAV-blockClass1" v-show="menu === 1">
      <div class="frmList">
        <masonry :cols="{default: 2, 1023: 3, 512: 2}" :gutter="15">
          <FileUpload :fileUsage="fileUsage" @imageAdd="imageAdd" />
          <div class="frmList__wrap" v-for="file in fileList" :key="file.id">
            <div
              class="frmList__thumb"
              :class="{
                'frmList__thumb--active': selectId === file.attachableId
              }"
              @click="imagePick(file.attachableId)"
            >
              <img :src="file.url" />
            </div>
          </div>
        </masonry>
        <div class="frmList__spinner" v-if="isListFetching">
          <i class="xi-spinner-1 xi-spin"></i>
        </div>
      </div>
      <GoTop className=".DAV-blockClass1" />
    </div>
    <!-- menu2 무료 이미지 unsplash -->
    <div class="DAV-blockClass DAV-blockClass2" v-show="menu === 2">
      <div class="frmList">
        <masonry
          v-if="unsplashList.length"
          :cols="{default: 2, 1023: 3, 512: 2}"
          :gutter="15"
        >
          <div
            class="frmList__wrap"
            v-for="(file, index) in unsplashList"
            :key="index"
          >
            <div
              class="frmList__thumb"
              :class="{
                'frmList__thumb--active': selectId === file.id
              }"
              @click="imagePick(file.id, `${file.urls.full}&w=1920`)"
            >
              <img :src="file.urls.small" />
            </div>
            <a :href="file.user.links.html" target="_blank">{{
              file.user.name
            }}</a>
          </div>
        </masonry>
        <div v-else class="errorLayout errorLayout__search">
          <p>일치하는 검색 결과가 없습니다.</p>
        </div>
        <div class="frmList__spinner" v-if="isListFetching">
          <i class="xi-spinner-1 xi-spin"></i>
        </div>
        <GoTop className=".DAV-blockClass2" />
      </div>
    </div>
    <button
      class="DAV-blockClass__submit"
      :class="setBtnClass"
      @click="menu === 1 ? imageSubmit() : unsplashImageRead()"
    >
      이미지 적용하기 <i class="xi-angle-right-min"></i>
    </button>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import {checkFiles} from "@/utils/file.js";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");
const ModuleConfirm = createNamespacedHelpers("ModuleConfirm");
const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleAttach = createNamespacedHelpers("ModuleAttach");

const PerPage = 20;
const PerBlock = 5;

export default {
  data() {
    return {
      isLoading: false,
      isFetching: false,
      isListFetching: false,
      menu: 1,
      originId: null,
      selectId: null,
      // my image
      page: 1,
      totalPage: 1,
      fileList: [],
      fileUsage: null,
      // unsplash
      unsplashPage: 1,
      unsplashTotalPage: 1,
      unsplashList: [],
      searchInput: "",
      searchValue: ""
    };
  },
  watch: {
    // 컴포넌트 이미지 목록
    getComponentImageListData(data) {
      if (this.page > 1) {
        this.fileList.push(...data.list);
      } else {
        this.fileList = data.list;
      }
      this.totalPage = data.header.totalPage;

      this.isLoading = false;
      this.isFetching = false;
      this.isListFetching = false;
    },
    // 컴포넌트 이미지 업로드
    getComponentImageAddData() {
      this.page = 1;
      this.selectId = null;
      this.imageInit();
    },
    // 컴포넌트 이미지 사용량 조회
    getComponentImageUsageData(data) {
      this.fileUsage = data.storage.usage;
    },
    // 파트너 이미지 목록
    getPartnerImageListData(data) {
      if (this.page > 1) {
        this.fileList.push(...data.list);
      } else {
        this.fileList = data.list;
      }
      this.totalPage = data.header.totalPage;

      this.isLoading = false;
      this.isFetching = false;
      this.isListFetching = false;
    },
    // 파트너 이미지 업로드
    getPartnerImageAddData() {
      this.page = 1;
      this.selectId = null;
      this.imageInit();
    },
    // 파트너 이미지 사용량 조회
    getPartnerImageUsageData(data) {
      this.fileUsage = data.storage.usage;
    }
  },
  computed: {
    // class binding - 이미지 적용하기 버튼
    setBtnClass() {
      return {
        "DAV-blockClass__submit--active": this.selectId
      };
    },

    ...ModuleEditor.mapGetters(["getPopup"]),
    ...ModuleAttach.mapGetters([
      "getComponentImageListData",
      "getComponentImageAddData",
      "getComponentImageDeleteData",
      "getComponentImageUsageData",
      "getPartnerImageListData",
      "getPartnerImageAddData",
      "getPartnerImageDeleteData",
      "getPartnerImageUsageData"
    ])
  },
  mounted() {
    // scroll event 추가
    if (document.querySelector(".DAV-blockClass1")) {
      document
        .querySelector(".DAV-blockClass1")
        .addEventListener("scroll", this.onScrollMenu1);
    }

    if (document.querySelector(".DAV-blockClass2")) {
      document
        .querySelector(".DAV-blockClass2")
        .addEventListener("scroll", this.onScrollMenu2);
    }

    this.index = this.getPopup.index;
    this.originId = JSON.parse(JSON.stringify(this.getPopup.value));

    this.isLoading = true;
    this.imageInit();
    this.unsplashImageList();
  },
  beforeDestroy() {
    // scroll event 삭제
    if (document.querySelector(".DAV-blockClass1")) {
      document
        .querySelector(".DAV-blockClass1")
        .removeEventListener("scroll", this.onScrollMenu1);
    }

    if (document.querySelector(".DAV-blockClass2")) {
      document
        .querySelector(".DAV-blockClass2")
        .removeEventListener("scroll", this.onScrollMenu2);
    }
  },
  methods: {
    // 컴포넌트 이미지 적용 취소
    goPrev() {
      let id =
        this.$route.path === "/option"
          ? this.getPopup.option?.componentOptionId
          : this.getPopup.option?.id;

      if (this.getPopup.option?.componentOption.componentTypeId === 16) {
        this.$EventBus.$emit(
          `MultiImageUpdateImg${id}`,
          this.originId,
          this.index,
          "cancel"
        );
        return;
      }
      if (this.getPopup.option?.componentOption.componentTypeId === 17) {
        this.$EventBus.$emit(
          `MultiImageURLUpdateImg${id}`,
          this.originId,
          this.index,
          "cancel"
        );
        return;
      }
      if (this.getPopup.option?.componentOption.componentTypeId === 18) {
        this.$EventBus.$emit(
          `MultiTextImageURLUpdateImg${id}`,
          this.originId,
          this.index,
          "cancel"
        );
        return;
      }
      this.$EventBus.$emit("updateImage" + this.index, {
        value: this.originId
      });
      this.setPopup({page: null});
    },
    // 컴포넌트 이미지 적용
    imageSubmit() {
      if (!this.selectId) return;
      const prefix = this.$route.query.mode === "partner" ? "p" : "c";
      let id =
        this.$route.path === "/option"
          ? this.getPopup.option?.componentOptionId
          : this.getPopup.option?.id;

      if (this.getPopup.option?.componentOption.componentTypeId === 16) {
        this.$EventBus.$emit(
          `MultiImageUpdateImg${id}`,
          `${prefix + this.selectId}`,
          this.index,
          "select"
        );
        return;
      }
      if (this.getPopup.option?.componentOption.componentTypeId === 17) {
        this.$EventBus.$emit(
          `MultiImageURLUpdateImg${id}`,
          `${prefix + this.selectId}`,
          this.index,
          "select"
        );
        return;
      }
      if (this.getPopup.option?.componentOption.componentTypeId === 18) {
        this.$EventBus.$emit(
          `MultiTextImageURLUpdateImg${id}`,
          `${prefix + this.selectId}`,
          this.index,
          "select"
        );
        return;
      }
      this.$EventBus.$emit("updateImage" + this.index, {
        value: `${prefix + this.selectId}`,
        type: "change"
      });
      this.setPopup({page: null});
    },
    // 컴포넌트 이미지 선택
    imagePick(key, url) {
      this.selectId = key !== this.selectId ? key : null;
      const prefix = this.$route.query.mode === "partner" ? "p" : "c";
      let id =
        this.$route.path === "/option"
          ? this.getPopup.option?.componentOptionId
          : this.getPopup.option?.id;

      if (this.selectId) {
        if (this.getPopup.option?.componentOption.componentTypeId === 16) {
          this.$EventBus.$emit(
            `MultiImageUpdateImg${id}`,
            this.menu === 1 ? `${prefix + this.selectId}` : url,
            this.index
          );
          return;
        }
        if (this.getPopup.option?.componentOption.componentTypeId === 17) {
          this.$EventBus.$emit(
            `MultiImageURLUpdateImg${id}`,
            this.menu === 1 ? `${prefix + this.selectId}` : url,
            this.index
          );
          return;
        }
        if (this.getPopup.option?.componentOption.componentTypeId === 18) {
          this.$EventBus.$emit(
            `MultiTextImageURLUpdateImg${id}`,
            this.menu === 1 ? `${prefix + this.selectId}` : url,
            this.index
          );
          return;
        }
        this.$EventBus.$emit("updateImage" + this.index, {
          value: this.menu === 1 ? `${prefix + this.selectId}` : url
        });
      }
    },
    // 컴포넌트 이미지 목록
    imageList() {
      let payload = {};
      payload.sortBy = "-id";
      payload.page = this.page;
      payload.perPage = PerPage;
      payload.perBlock = PerBlock;

      if (this.$route.query.mode === "partner") {
        this.actPartnerImageList(payload).then(() => {});
      } else {
        this.actComponentImageList(payload).then(() => {});
      }
    },
    // 컴포넌트 이미지 업로드
    imageAdd(file) {
      this.isFetching = true;

      let payload = {};
      payload.files = file;

      if (this.$route.query.mode === "partner") {
        this.actPartnerImageAdd(payload).then(() => {});
      } else {
        this.actComponentImageAdd(payload).then(() => {});
      }
    },
    // 컴포넌트 이미지 사용량 조회
    imageUsage() {
      if (this.$route.query.mode === "partner") {
        this.actPartnerImageUsage().then(() => {});
      } else {
        this.actComponentImageUsage().then(() => {});
      }
    },
    // 컴포넌트 이미지 init
    imageInit() {
      this.imageList();
      this.imageUsage();
    },
    // menu tab 변경
    changeMenu(v) {
      this.selectId = null;
      this.menu = v;
    },
    // menu1 scroll event
    onScrollMenu1(e) {
      const {scrollHeight, scrollTop, clientHeight} = e.target;

      let atBottom = scrollHeight <= Math.round(scrollTop) + clientHeight;
      if (atBottom && !this.isListFetching && this.page < this.totalPage) {
        this.page++;
        this.isListFetching = true;
        this.imageInit();
      }
    },
    // menu2 scroll event
    onScrollMenu2(e) {
      const {scrollHeight, scrollTop, clientHeight} = e.target;

      let atBottom = scrollHeight <= Math.round(scrollTop) + clientHeight;
      if (
        atBottom &&
        !this.isListFetching &&
        this.unsplashPage < this.unsplashTotalPage
      ) {
        this.unsplashPage++;
        this.isListFetching = true;
        this.unsplashImageList();
      }
    },
    // unsplash 검색 삭제
    unsplashSearchDelete() {
      this.searchInput = "";
      this.unsplashImageList("search");
    },
    // unsplash 이미지 목록
    unsplashImageList(type) {
      if (type === "search") {
        document.querySelector(".DAV-blockClass2").scrollTo({top: 0});
        this.searchValue = JSON.parse(JSON.stringify(this.searchInput));
        this.unsplashPage = 1;
        this.selectId = null;
      }
      let payload = {};
      payload.page = this.unsplashPage;
      payload.perPage = 20;
      if (this.searchValue !== "") {
        payload.search = this.searchValue;
      }
      const api =
        this.searchValue !== ""
          ? this.actUnsplashSearchImageList
          : this.actUnsplashImageList;
      if (this.unsplashPage === 1) this.isLoading = true;
      api(payload).then(response => {
        if (this.unsplashPage > 1) {
          this.unsplashList = this.unsplashList.concat(response.data.list);
        } else {
          this.unsplashList = response.data.list;
        }
        this.unsplashTotalPage = response.data.header.totalPage;
        this.isListFetching = false;
        this.isLoading = false;
      });
    },
    // unsplash 이미지 상세
    unsplashImageRead() {
      if (!this.selectId) return;
      let payload = {};
      payload.id = this.selectId;

      this.isLoading = true;
      this.actUnsplashImageRead(payload).then(response => {
        const files = [];
        files.push(response.data);
        let validFiles = checkFiles(files, this.fileUsage);
        if (!validFiles.result) {
          this.isLoading = false;
          this.setConfirm({
            isVisible: true,
            message: validFiles.msg,
            messageBtn: `이미지 관리 바로가기`,
            event: () => window.open("/admin/image")
          });
          return;
        }
        this.unsplashImageDownload();
      });
    },
    // unsplash 이미지 다운
    unsplashImageDownload() {
      if (!this.selectId) return;
      let payload = {};
      payload.id = this.selectId;

      if (this.$route.query.mode === "partner") {
        payload.mode = "partner";
      }

      this.actUnsplashImageDownload(payload).then(response => {
        this.selectId = `${response.data.attachableId}`;
        this.imageSubmit();
        this.isLoading = false;
      });
    },

    ...ModuleAlert.mapMutations(["setAlert"]),
    ...ModuleConfirm.mapMutations(["setConfirm"]),
    ...ModuleEditor.mapMutations(["setPopup"]),
    ...ModuleAttach.mapActions([
      "actComponentImageList",
      "actComponentImageAdd",
      "actComponentImageDelete",
      "actComponentImageUsage",
      "actUnsplashImageList",
      "actUnsplashSearchImageList",
      "actUnsplashImageRead",
      "actUnsplashImageDownload",
      "actPartnerImageList",
      "actPartnerImageAdd",
      "actPartnerImageDelete",
      "actPartnerImageUsage",
      "actPartnerImageRead"
    ])
  },
  components: {
    GoTop: () => import("@/components/modules/GoTop.vue"),
    Spinner: () => import("@/components/basis/spinner.vue"),
    DualballSpinner: () => import("@/components/basis/dualball-spinner.vue"),
    FileUsage: () => import("@/components/modules/FileUsage.vue"),
    FileUpload: () => import("@/components/modules/FileUpload.vue"),
    ComponentTitle: () => import("@/components/editor/ComponentTitle.vue")
  }
};
</script>

<style lang="scss" scoped>
.title-wrap {
  display: flex;
  align-items: center;

  li {
    cursor: pointer;
    color: #d2f8ec;
  }

  .title-active {
    color: #fff;
  }

  .line {
    width: 1px;
    height: 12px;
    background: #8be1c5;
    margin: 0 16px;
    cursor: auto;
  }
}
.search-wrap {
  width: auto;
  height: 46px;
  padding: 0 16px;
  background-color: #54c7a2;
  display: flex;

  .search-input {
    position: relative;
    width: calc(100% - 62px);

    input {
      height: 36px;
      box-sizing: border-box;
      width: 100%;
      border: 1px solid #d8dae5;
      border-radius: 4px;
      padding-left: 10px;
      outline: none;

      &::placeholder {
        color: #aaa;
      }
    }

    .cancel-button {
      cursor: pointer;
      width: 20px;
      height: 20px;
      background: #b7b7b7;
      color: #fff;
      text-align: center;
      line-height: 20px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      right: 12px;
      font-size: 11px;
      font-weight: 600;
    }
  }

  .search-button {
    width: 52px;
    height: 36px;
    background: #309574;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    margin-left: 10px;
    cursor: pointer;
  }
}
.goTop {
  bottom: 80px;
}
.DAV-blockClass {
  top: 56px + 46px;
  bottom: 64px;
}
.frmList {
  position: relative;
  padding: 16px 16px 48px;
  border-bottom: none;

  &__wrap {
    margin-bottom: 16px;

    a {
      font-weight: 500;
      font-size: 12px;
      text-decoration-line: underline;
      color: #8d8d8d;
      margin-top: 8px;
    }
  }

  &__thumb {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    &--active::before {
      background-color: rgba(48, 149, 116, 0.6);
      background-image: url($path + "ico_selected.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
    img {
      display: block;
      width: 100%;
    }
  }

  &__spinner {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #309574;
      font-size: 30px;
    }
  }
}
.DAV-blockClass__submit {
  z-index: 2;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  background-color: #dcdcdc;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;

  &--active {
    background-color: #54c7a2;
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-blockClass__submit {
    width: 421px;
  }
  .DAV-workshop__heading {
    h3 {
      padding-left: 32px;
    }
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-workshop__trunk {
    top: 40px;
    &--option-mode {
      top: 0;
    }
  }
}
</style>
